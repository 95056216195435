<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="6" lg="6" xl="6">
                        <div class="font-weight-bold body-1">
                            Detalles de Orden de trabajo
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Productos</p>
                    </v-col>
                </v-row>

                <v-row justify="center" >
                    <v-col class="py-3" cols="12" md="6" lg="6" xl="6">
                        <table-items data-cy="table-items" :props="tableProps"  :items="tableProps.items">
                                <template v-slot:body.append>
                                            <tr style="height: 10px !important;" class="no-border">
                                                <th class="text-right black--text " colspan="3" style="height: 10px !important;">SUBTOTAL</th>
                                                <th class="text-right black--text subtitle-2 pr-1" style="height: 10px !important;">{{subtotal}}</th>
                                            </tr>

                                            <tr style="height: 10px !important;" class="no-border">
                                                <th class="text-right black--text" colspan="3" style="height: 10px !important;">IVA</th>
                                                <th class="text-right black--text subtitle-2 pr-1" style="height: 10px !important;">{{iva}}</th>
                                            </tr>
                                            <tr style="height: 10px !important;" class="no-border">
                                                <th class="text-right black--text" colspan="3" style="height: 10px !important;">TOTAL</th>
                                                <th class="text-right black--text subtitle-2 pr-1" style="height: 10px !important;">{{total}}</th>
                                            </tr>
                                        
                                </template>
                        </table-items>
                        
                    </v-col>
                    
                </v-row>


                <v-row justify="center" v-if="payments.length > 0">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Pagos</p>
                    </v-col>
                </v-row>

                <v-row class="mb-5" justify="center" v-for="(payment, index) in payments" :key="index">
                    <v-col cols="12" md="6" lg="6" xl="6" >
                        <v-row justify="center" no-gutters>
                            <v-col class="my-0 py-0">
                                <div >
                                    <v-row justify="center" align="center" no-gutters class="my-2">
                                       <!-- <div  class=""> -->
                                             <v-col align-self="center" class="mb-0"  cols="6">
                                                <span class="font-weight-bold normalText--text " data-cy="data-details-category-item">
                                                    {{ payment.invoice_number }} 
                                                   
                                                </span>
                                            </v-col>
                                            <!-- <v-icon color="primary">mdi-pencil</v-icon> -->
                                       <!-- </div> -->
                                        <v-col class="text-right">
                                            <v-btn data-cy="data-details-payment-edit-link" icon text :to="{ name: 'SaleOrdersEditPayment', params: { saleOrderId: saleOrderId, paymentId:payment.id} }" >
                                                <v-icon color="primary">mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn data-cy="data-details-payment-delete-link" icon text target="_blank" @click="openDeleteModal(payment)" >
                                                <v-icon color="primary" >mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                            </v-col>
                        </v-row>

                        <details-item :detailsItems="getPaymentItem(payment)" data-cy="data-payment-details-items"></details-item>


                    </v-col>
                    
                </v-row>


                <v-row justify="center" v-if="tracings.length > 0">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">SEGUIMIENTOS</p>
                    </v-col>
                </v-row>

                <v-row class="mb-5" justify="center" v-for="(tracing, index) in tracings" :key="index+999">
                    <v-col cols="12" md="6" lg="6" xl="6" >

                        <details-item :detailsItems="getTracingtItem(tracing)" data-cy="data-tracing-details-items"></details-item>

                    </v-col>
                    
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>

            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            saleOrderId: this.$route.params.saleOrderId,
            loading: false,
            subtotal: 0,
            iva: 0,
            total: 0,
            items: [
               
                
            ],
            payments: [],
            tracings: [],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "SaleOrders",
                block:false,
                click: () => {}
            },

            tableProps: {
				headers: [
                     {
						text: 'Producto',
						align: 'left break-words',
						value: 'product_name',
                        width:"50%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Precio',
						align: 'right',
						value: 'amount',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'right',
						value: 'subtotal',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},

					
				],
				items: [],
				search: '',
				loading: false
			},

            modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.delete()}
            },
            
           
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.saleOrder.get(this.saleOrderId)
			.then((response) => {
                console.log(response);
                this.subtotal = 0;
                this.iva = 0;
                this.total = 0;

               
               
                this.items= [
                    { concepto: 'Folio', index: 'prospect', valor:response.data.folio},
                    { concepto: 'Cliente', index: 'project_name', valor:response.data.client.name},
                    { concepto: 'Obra', index: 'foreign_exchange', valor: `${response.data.work.folio} - ${response.data.work.name}` },
                    { concepto: 'Fecha', index: 'description', valor:Utils.globalDateFormat(response.data.date??"")},
                    { concepto: 'Factura PDF', index: 'header', valor:response.data.pdf_url??"", isFile:response.data.pdf_url!=null ? true:false},
                    { concepto: 'Factura XML', index: 'header', valor:response.data.xml_url??"", isFile:response.data.xml_url!=null ? true:false},
                ];

                //products
                this.tableProps.items = response.data.products.map((item) => {
                    this.subtotal = this.subtotal + (item.quantity * item.amount);
                    
                    return {...item, subtotal: Utils.currencyFormat(parseFloat(item.quantity * item.amount).toFixed(2), ""), amount: Utils.currencyFormat(item.amount, "")};
                });

                this.iva = this.subtotal*.16;
                this.total = this.subtotal + this.iva;

                this.subtotal = Utils.currencyFormat(this.subtotal, "");
                this.iva = Utils.currencyFormat(this.iva, "");
                this.total = Utils.currencyFormat(this.total, "");

               
                this.payments = response.data.payments;
                this.tracings = response.data.tracings;
            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
        getPaymentItem(payment){
            return [
                { concepto: 'Fecha', index: 'description', valor:Utils.globalDateFormat(payment.date??"")},
                { concepto: 'Monto', index: 'prospect', valor:Utils.currencyFormat(payment.amount)},
                { concepto: 'Banco', index: 'project_name', valor:payment.bank_account.name},
                { concepto: 'Cuenta', index: 'project_name', valor:payment.bank_account.account_number},
                { concepto: 'Folio de la factura', index: 'project_name', valor:payment.invoice_number},
                { concepto: 'Adjunto', index: 'header', valor:payment.invoice_url??"", isFile:payment.invoice_url!=null ? true:false},
            ];
        },
        getTracingtItem(tracing)
        {
            return [
                { concepto: 'Fecha', index: 'description', valor:Utils.globalDateFormat(tracing.date??"")},
                { concepto: 'Próximo contacto', index: 'prospect', valor:Utils.globalDateFormat(tracing.next_date)},
                { concepto: 'Adjunto', index: 'header', valor:tracing.file_url??"", isFile:tracing.file_url!=null ? true:false},
                { concepto: 'Comentario', index: 'project_name', valor:tracing.comments},
            ];
        },

        openDeleteModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.date}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.saleOrder.deletePayment(this.saleOrderId, this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }

    .no-border > th{
        border-bottom: 0px red solid !important;
    }
</style> 